import { Component, Vue } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import { Organisation } from '@/shared/model/organisation';

const organizationModule = namespace('organization');
const logger = new Logger('exports');

@Component({
  components: {},
})
export default class ExportsView extends Vue {
  @organizationModule.Getter('getOrganization')
  private organisation!: Organisation;
  @organizationModule.Action('getOrganization')
  private actionGetOrganization!: any;

  created() {
    this.actionGetOrganization(this.organisation.id);
  }
}
